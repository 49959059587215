<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="confirmTaskListModal"
      :title="''"
      :buttons-hidden="true"
    >
      <div class="flex mb-2">
        <p class="w-full mb-4 text-grey">{{ $t('Please, choose one of the following actions before completing the task list') }}</p>
      </div>
      <div class="flex mb-2">
        <div class="w-1/2">
          <vs-radio name="reason" v-validate="'required'" v-model="answer" :vs-value="1">{{ $t('Full Amount') }}</vs-radio>
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first("reason") }}</span>
        </div>
        <div class="w-1/2">
          <p>{{cashCollected.toFixed(2)}}</p>
        </div>
      </div>
      <div class="flex mb-2">
        <div class="flex w-full md:w-1/3 items-center">
          <vs-radio name="reason" v-validate="'required'" v-model="answer" :vs-value="2">{{ $t('partial Amount') }}</vs-radio>
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first("reason") }}</span>
        </div>
        <div class="w-1/2 md:w-1/3 items-center pr-5">
          <vs-input name="Cash Deposited" :label-placeholder="$t('Cash Deposited')" v-model="cashDeposited" class="mt-10 w-full" :disabled="answer !== 2" v-validate="`required|decimal|max_value:${cashCollected}|min_value:0`"/>
          <p class="text-danger text-sm w-full" v-show="errors.has('Cash Deposited')">{{ errors.first('Cash Deposited') }}</p> 
        </div>
        <div class="flex w-1/2 md:w-1/3 items-center">
          <div>
            <div class="text-lg text-black">{{ $t('Shortfall') }}</div>
            <span class="text-red"> {{(cashCollected - cashDeposited).toFixed(2)}} </span>
          </div>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-radio name="reason" v-validate="'required'" v-model="answer" :vs-value="3">{{ $t('No Show') }}</vs-radio>
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first("reason") }}</span>
        </div>
      </div>
      <div class="mt-4 text-center">
        <vs-button color="success" class="active-btn btn w-1/2" @click="confirmCashCollected">{{ $t('Confirm') }}</vs-button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['confirmTaskListModal', 'cashCollected'],
  data () {
    return {
      answer: '',
      cashDeposited: null
    }
  },
  watch: {
    'answer' (val) {
      if (val !== 2) this.cashDeposited = 0
    }
  },
  computed: {
  },
  methods: {
    closeModal () {
      this.$emit('confirmTaskListModal', false)
      this.$emit('loadTaskSheetOrders')
    },
    confirmCashCollected () {
      this.$validator.validateAll().then(result => {
        if (result) {

          const data = {
            cash_deposited : this.answer === 1 ? Number(this.cashCollected).toFixed(2) : this.answer === 3 ? Number(0) : this.answer === 2 ? Number(this.cashDeposited) : ''
          }
          sendRequest(false, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/confirm-cash-collected/`, 'post', data, true,
            (response) => {
              this.$vs.notify({
                color: 'success',
                text: response.data.message,
                position: 'top-center'
              })
              this.closeModal()
              this.$emit('loadShipments')
              this.$emit('confirmTaskListModal', false)
              this.$emit('confirmUpdateTaskListStatus', 'completed')
            })
        }
      })
    }
  },
  created () {
  },
  components: {
    ShipbluPrompt
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
