<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="activeTaskFlag"
      :title="$t('Move order to another task sheet')" :buttons-hidden="true">
        <shipblu-table
          :sst="true"
          search
          orders
          multiple
          v-model="selected"
          :data="taskLists"
          pagination
          :max-items="maximumItems"
          :tableLoader="tableLoader"
        >
          <template slot="thead">
            <shipblu-th>{{$t('Created On')}}</shipblu-th>
            <shipblu-th>{{$t('Agent Name')}}</shipblu-th>
            <shipblu-th>{{$t('Agent Type')}}</shipblu-th>
            <shipblu-th>{{$t('Status')}}</shipblu-th>
            <shipblu-th>{{$t('Packages')}}</shipblu-th>
            <shipblu-th>{{$t('Stops')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
              <shipblu-td :data="data[indextr].agent" :style="`color: ${getStyle(data[indextr])}`">
                <span class="text-darkblue">{{ common.splitYearOfDate(data[indextr].created) }}</span>
              </shipblu-td>

              <shipblu-td :data="data[indextr].agent">
                {{ data[indextr].merchant_agent ? data[indextr].merchant_agent.first_name + ' ' + data[indextr].merchant_agent.last_name : data[indextr].customer_agent.first_name + ' ' + data[indextr].customer_agent.last_name }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].agent">
                {{ data[indextr].merchant_agent ? 'First Mile Agent' : 'Last Mile Agent' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
                <div class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                </div>
              </shipblu-td>

              <shipblu-td :data="data[indextr].packages">
                {{ data[indextr].packages_count }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].stops">
                {{ data[indextr].stops }}
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
        <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
        <span class="text-danger text-sm" v-show="clicks > 0">This field is required</span>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button :disabled="selected.length === 0" :class="selected.length === 0 ? 'disable-btn' : 'active-btn'" @click="addOrders" class="btn">{{ $t('Move') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['activeTaskFlag', 'taskSheetOrder', 'sequence', 'taskSheetType'],
  data () {
    return {
      common,
      selected: [],
      taskLists: [],
      offset: 0,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      totalRows: 0,
      tableLoader: false,
      clicks: 0,
      newTaskSheet: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadTaskLists()
    },
    selected () {
      const lastSelected = this.selected[this.selected.length - 1]
      if (this.selected.length > 1) {
        this.selected = []
        this.selected.push(lastSelected)
      }
      if (this.selected.length > 0) this.clicks = 0
    }
  },
  methods: {
    loadTaskLists () {
      this.tableLoader = true
      const query = `&offset=${this.offset}&limit=${this.maximumItems}&created=${new Date().toISOString().split('T')[0]}&warehouse_code=${this.$route.params.warehouseID}`
      sendRequest(true, false, this, `api/v2/task-sheets/?status=created,in_progress${query}`, 'get', null, true, 
        (response) => {
          this.taskLists = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    addOrders () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          const taskSheetOrdersIds = []
          const cashCollectionsIds = []
          this.taskSheetOrder.forEach(item => {
            if (item.tracking_number) {
              if (item.tracking_number[0] === '1' || (item.tracking_number[0] === '3' && item.tracking_number[1] === '1') || item.tracking_number[0] === '2'
              || (item.tracking_number[0] === '3' && item.tracking_number[1] === '2')) {
                taskSheetOrdersIds.push(item.id)
              } else if (item.tracking_number[0] === '4') {
                cashCollectionsIds.push(item.id)
              } 
            } else {
              taskSheetOrdersIds.push(item.supplies_request.id)
            }
          })
          const sendDate = {
            'orders': taskSheetOrdersIds,
            'cash_collections': cashCollectionsIds,
            'old_task_sheet': this.$route.params.taskListID,
            'new_task_sheet': this.selected[0].id,
            'task_sheet_type': this.taskSheetType
          }
          sendRequest(false, false, this, 'api/v1/task-sheets/transformation/', 'post', sendDate, true,
            () => {
              this.$emit('loadShipments')
              this.$emit('activeTaskFlag', false)
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Task List'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
            }, (error) => {
              common.notifyResponseError(this, error)
              this.$vs.loading.close()
            })
        }
      })
    },
    closeModal () {
      this.selected = []
      this.$emit('activeTaskFlag', false)
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    }
  },
  created () {
    this.loadTaskLists()
  },
  components: {
    vSelect,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPrompt,
    ShipbluPagination
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";

.con-pagination-table{
  display: none;
}
.con-vs-dialog.shipment-modal {
  .vs-dialog {
    max-width: 800px;
  }
}
</style>